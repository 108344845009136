import React, { useState } from "react";
import "./footer.css";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateTo = (routeName) => {
    navigate(routeName);
  };
  return (
    <div className="footer-container">
      <div className="footer-box">
        <div className="img-container">
          <img src={require("../../Assets/logo.png")} />
        </div>
      </div>
      <div className="footer-box box2">
        <div className="footer-link" onClick={() => navigateTo("/")}>
          Home
        </div>
        <div className="footer-link" onClick={() => navigateTo("/about")}>
          About
        </div>

        {/* <div className='footer-social-container'>
          <FiFacebook className='sical-icon-name' />
          <FiInstagram className='sical-icon-name' />
          <FiLinkedin className='sical-icon-name' />
          <FiTwitter className='sical-icon-name' />
        </div> */}
        <p>All rights reserved. CPR Consultancy</p>
      </div>
    </div>
  );
}
