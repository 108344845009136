import React, { useState } from "react";
import "./header.css";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header({ props }) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const navigateTo = (routeName) => {
    navigate(routeName);
  };
  return (
    <div className="header-container">
      <div className="header-links-container">
        <div
          className={`header-link ${
            location.pathname === "/" ? "active" : null
          }`}
          onClick={() => navigateTo("/")}
        >
          Home
        </div>
        <div
          className={`header-link ${
            location.pathname === "/about" ? "active" : null
          }`}
          onClick={() => navigateTo("/about")}
        >
          About
        </div>
      </div>
      {/* <div className='header-social-container'>
        <FiFacebook className='sical-icon-name' />
        <FiInstagram className='sical-icon-name' />
        <FiLinkedin className='sical-icon-name' />
        <FiTwitter className='sical-icon-name' />
      </div> */}
    </div>
  );
}
