import React, { useRef, useState } from "react";
import "./home.css";
import Header from "../../Components/Header/header";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../Components/Footer/footer";
export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const navigateTo = (routeName) => {
    navigate(routeName);
  };

  const divRef = useRef(null);

  const onPressBooking = () => {
    divRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  return (
    <>
      <Header />
      <div ref={divRef} className="home-container">
        <div className="logo-wrapper">
          <div className="logo-container">
            <img src={require("../../Assets/logo.png")} />
          </div>
        </div>
        <div className="first-section">
          <div className="first-section-overlay">
            <div className="first-section-detail">
              <div className="header-for-mobile">
                <div
                  className="header-links-container"
                  style={{ justifyContent: "center", marginBottom: 10 }}
                >
                  <div
                    className={`header-link ${
                      location.pathname === "/" ? "active" : null
                    }`}
                    onClick={() => navigateTo("/")}
                  >
                    Home
                  </div>
                  <div
                    className={`header-link ${
                      location.pathname === "/about" ? "active" : null
                    }`}
                    onClick={() => navigateTo("/about")}
                  >
                    About
                  </div>
                </div>
              </div>

              <h2>Welcome to CPR-Consultancy</h2>
              <p>
                Navigate Healthcare's Multifaceted Landscape with CPR -
                Expertise, Innovation, and Impact.
              </p>
              {/* <div
                style={{ display: "flex", justifyContent: "center " }}
                className="header-social-container"
              >
                <FiFacebook className="sical-icon-name" />
                <FiInstagram className="sical-icon-name" />
                <FiLinkedin className="sical-icon-name" />
                <FiTwitter className="sical-icon-name" />
              </div> */}
            </div>
          </div>
        </div>

        <div className="second-section" style={{ marginBottom: 0 }}>
          <div className="flex-row-with">
            <div className="image-container">
              <img src={require(".././../Assets/pic1.jpg")} alt="" />
            </div>
            <div>
              <p>
                Be a hero in your community! Learn life-saving CPR and First Aid
                skills with our friendly and experienced instructors.Our
                flexible pricing means quality training fits your budget. Gain
                confidence, save lives, and make a difference today! Contact us
                to schedule your personalized training session. Together, let's
                create safer, more prepared communities. Don't wait for an
                emergency to act – empower yourself and your team with essential
                CPR and First Aid knowledge Thank you !!!
              </p>
            </div>
          </div>
        </div>

        <div className="image-section" style={{ marginBottom: 0 }}>
          <div className="row-image-container">
            <div className="image-container">
              <img src={require(".././../Assets/pic2.jpg")} alt="" />
            </div>
            <div className="image-container">
              <img src={require(".././../Assets/pic3.jpg")} alt="" />
            </div>

            <div className="image-container">
              <img src={require(".././../Assets/pic4.jpg")} alt="" />
            </div>
            <div className="image-container">
              <img src={require(".././../Assets/pic5.jpg")} alt="" />
            </div>
            <div className="image-container">
              <img src={require(".././../Assets/pic6.jpg")} alt="" />
            </div>
            <div className="image-container">
              <img src={require(".././../Assets/pic7.jpg")} alt="" />
            </div>
          </div>
        </div>

        <div className="second-section" style={{ marginBottom: 0 }}>
          <div>
            <p>
              <span style={{ color: "#F0284A", fontWeight: "bold" }}>
                Thank You
              </span>{" "}
              for considering our CPR, AED, and First Aid training services. We
              are dedicated to providing exceptional training experiences that
              empower individuals to respond confidently and effectively in
              emergencies. Contact us today to discuss your training needs and
              how we can tailor a program to suit you. Your safety is our
              priority.
            </p>
          </div>
        </div>

        <div className="second-section">
          <h1>Booking Schedule</h1>
          <div className="flex-row">
            <div className="box">
              <div className="title">Heart saver CPR/AED/First Aid</div>
              <div className="price">Price:$100</div>
              <div className="time">Time:10am - 2:30pm Saturdays</div>
            </div>
            <div className="box">
              <div className="title">Red Cross BLS Certification</div>
              <div className="price">Price:$100</div>
              <div className="time">Time: 10am - 2:30pm Saturdays</div>
            </div>
            <div className="box">
              <div className="title">BLS Healthcare Provider AHA</div>
              <div className="price">Price:$100</div>
              <div className="time">Time: 10am - 2:30pm Monday and Tuesday</div>
            </div>
            <div className="box">
              <div className="title">BLS Healthcare Provider Renewal</div>
              <div className="price">Price:$80</div>
              <div className="time">
                Time: 11am - 2pm Wednesday and Thursday
              </div>
            </div>
            <div className="box">
              <div className="title">BLS Provider Skills and Testing</div>
              <div className="price">Price:$60</div>
              <div className="price">
                This is only for the people who took the online portion and just
                need the skill to complete the training and be certified.
              </div>
              <div className="time">
                Time: 4:30pm - 6:30pm Wednesday and Thursday
              </div>
            </div>
            <div className="box">
              <div className="title">First Aid training</div>
              <div className="price">Price:$60</div>
              <div className="time">Time: 10am - 4pm Friday</div>
            </div>
            <div className="box">
              <div className="title">First Aid training</div>
              <div className="price">Price:$60</div>
              <div className="time">Time: 10am - 4pm Friday</div>
            </div>
          </div>
          <p style={{ color: "black", fontWeight: "bold" }}>
            For Booking click{" "}
            <span
              onClick={() => onPressBooking()}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              here
            </span>
          </p>
          <div className="alert-container">
            <p>Contact us to schedule an appointment</p>
            <p>
              We can come to you only in corporate spaces <br />
              There is group discount available
            </p>
          </div>
        </div>
        <div className="third-section">
          <div className="banner-container">
            <img src={require("../../Assets/flyer.jpg")} />
          </div>
        </div>
        <div className="contact-us-container">
          <div className="left-box">
            <h2>Contact us</h2>
            <div className="left-box-list">Telephone:917-753-3732</div>
            <div className="left-box-list">E-mail: Htcprclass@gmail.com</div>
            <div className="left-box-list">
              Location: Long pond Pennsylvania
            </div>
          </div>
          <div className="right-box">
            <div className="form-group">
              <div className="input-title">Name*</div>
              <input className="input" type="text" />
            </div>
            <div className="form-group">
              <div className="input-title">Email*</div>
              <input className="input" type="email" />
            </div>
            <div className="form-group">
              <div className="input-title">Message*</div>
              <textarea className="input message-input" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
