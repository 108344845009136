import React, { useState } from "react";
import "./about.css";
import Header from "../../Components/Header/header";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import Footer from "../../Components/Footer/footer";
import { useNavigate, useLocation } from "react-router-dom";
export default function About() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const navigateTo = (routeName) => {
    navigate(routeName);
  };
  return (
    <>
      <Header />
      <div>
        <div className="logo-wrapper">
          <div className="logo-container">
            <img src={require("../../Assets/logo.png")} />
          </div>
        </div>
        <div className="first-section">
          <div className="first-section-overlay">
            <div className="first-section-detail">
              <div className="header-for-mobile">
                <div
                  className="header-links-container"
                  style={{ justifyContent: "center", marginBottom: 10 }}
                >
                  <div
                    className={`header-link ${
                      location.pathname === "/" ? "active" : null
                    }`}
                    onClick={() => navigateTo("/")}
                  >
                    Home
                  </div>
                  <div
                    className={`header-link ${
                      location.pathname === "/about" ? "active" : null
                    }`}
                    onClick={() => navigateTo("/about")}
                  >
                    About
                  </div>
                </div>
              </div>
              <h2>Welcome to CPR-Consultancy</h2>
              <p>
                Navigate Healthcare's Multifaceted Landscape with CPR -
                Expertise, Innovation, and Impact.
              </p>
              <div
                style={{ display: "flex", justifyContent: "center " }}
                className="header-social-container"
              >
                <FiFacebook className="sical-icon-name" />
                <FiInstagram className="sical-icon-name" />
                <FiLinkedin className="sical-icon-name" />
                <FiTwitter className="sical-icon-name" />
              </div>
            </div>
          </div>
        </div>
        <div className="second-section">
          <h1>ABOUT US</h1>
          <p style={{ textAlign: "justify", padding: "0 10px" }}>
            Establishing a CPR and first aid business in our rural community
            stemmed from recognizing the critical need for accessible emergency
            medical training. Rural areas often face challenges accessing
            immediate healthcare, underscoring the importance of equipping
            locals with life-saving skills. Our passion lies in empowering
            individuals with the knowledge and confidence to respond effectively
            in emergencies, potentially saving lives. We believe that education
            is the cornerstone of preparedness, and by offering comprehensive
            CPR and first aid training, we aim to cultivate a community of first
            responders. Our commitment to education extends beyond profit; it's
            driven by a genuine desire to enhance community resilience and
            well-being. We firmly believe that everyone should have the
            opportunity to learn these essential skills, as they can make a
            profound difference in the outcome of emergencies. Our mission is to
            foster a culture of preparedness, where every member of our
            community feels empowered to act swiftly and decisively in times of
            crisis.
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}
